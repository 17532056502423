import "../assets/styles/bootstrap.min.css";
import "../assets/styles/style.css";

import BrandLogo from "../assets/images/Rapid learn-logos_transparent1.png";
import { Link } from "react-router-dom";

function HeaderLogin() {
  return (
    <header className="sticky-top bg-white shadow-sm">
      <nav className="navbar navbar-expand-lg navbar-light container py-0">
        <Link to="/" className="navbar-brand d-flex align-items-center">
          <img
            src={BrandLogo}
            className="brand-logo"
            width="150"
            alt="Rapid Learn"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          
        </div>
      </nav>
    </header>
  );
}

export default HeaderLogin;
