import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import signup from "./assets/images/signup.png";
import learn from "./assets/images/learn.jpg";
import sched from "./assets/images/sched.jpg";
import stud from "./assets/images/students_09.jpg";
import { Link } from "react-router-dom";

function GetStarte() {
  return (
    <>
      <main>
        <div className="jumbotron jumbotron-fluid text-center bg-dark text-white py-2 w-100">
          <div className="container">
            <h1 className="display-4">Get Started</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center bg-transparent">
                <li className="breadcrumb-item">
                  <a href="/" className="text-white">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Get Started
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          id="tm-about-area"
          className="tm-about-area tm-section tm-padding-section bg-white"
        >
          <div className="container" style={{ marginTop: 0, paddingTop: 60 }}>
            <div className="row align-items-center">
              <div className="col-lg-12 mb-5">
                <div className="tm-videobox">
                  {/* <h2>Unlock Your Learning Potential with Spaced Repetition</h2> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-videobox">
                  <img
                    src={signup}
                    width={500}
                    className="img-fluid"
                    alt="signup"
                  />
                  <Link to={"/"}></Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-about-content">
                  <h2>Steps to Register on Rapid Learn</h2>
                  <ul className="list-unstyled mt-4">
                    <li className="mb-4">
                      <div className="d-flex align-items-start">
                        <i className="ion-android-checkmark-circle me-3" />
                        <div>
                          <strong>Step 1:</strong> Go to the{" "}
                          <a href="/">Rapid Learn homepage</a>
                        </div>
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="d-flex align-items-start">
                        <i className="ion-android-checkmark-circle me-3" />
                        <div>
                          <strong>Step 2:</strong> Click on the{" "}
                          <a href="/auth/sign-up">Sign Up</a> button at the top right
                          corner
                        </div>
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="d-flex align-items-start">
                        <i className="ion-android-checkmark-circle me-3" />
                        <div>
                          <strong>Step 3:</strong> Fill in your details:
                          <ul className="ms-4 mt-2">
                            <li>Email</li>
                            <li>Password</li>
                            <li>Confirm Password</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="d-flex align-items-start">
                        <i className="ion-android-checkmark-circle me-3" />
                        <div>
                          <strong>Step 4:</strong> Log in to your account using
                          your email and password on the{" "}
                          <a href="/auth/login">Login</a> page
                        </div>
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="d-flex align-items-start">
                        <i className="ion-android-checkmark-circle me-3" />
                        <div>
                          <strong>Step 5:</strong> Start exploring and add your
                          studied topic to begin your learning journey!
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-lg-6">
                <div className="tm-whyus-content">
                  <h2>What is Spaced Repetition?</h2>
                  <div className="tm-whyus-blocks">
                    <div className="tm-whyus-block">
                      <p>
                        Spaced repetition is a science-backed method that has
                        transformed the way we learn and retain information.
                        It's not just another study technique; it's a
                        game-changer in the world of learning and memory
                        retention. This method takes advantage of the
                        psychological spacing effect, which suggests that we
                        remember information better when it's reviewed at
                        increasing intervals over time.
                      </p>
                    </div>
                  </div>
                  <h2 className="mt-5">Why Choose Spaced Repetition?</h2>
                  <div className="tm-whyus-blocks">
                    <div className="tm-whyus-block">
                      <p>
                        Efficiency: Focus on what matters most with targeted
                        review sessions. Retention: Remember information longer
                        and retrieve it more readily. Personalized Learning:
                        Adapt your study schedule to fit your pace and needs.
                        Accelerated Learning: Speed up your learning process
                        with optimized review intervals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-whyus-image">
                  <img src={learn} className="img-fluid" alt="us" />
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-lg-6">
                <div className="tm-whyus-image">
                  <img src={sched} className="img-fluid" alt="us" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-whyus-content">
                  <h2>Steps to Start Your Spaced Repetition Journey:</h2>
                  <div className="tm-whyus-blocks">
                    <div className="tm-whyus-block">
                      <p>
                        Sign Up: Create your account to access personalized
                        study plans and tracking features. Set Your Goals:
                        Define what you want to learn or improve upon using
                        spaced repetition. Add Topics: Start by adding topics or
                        subjects you want to study. Organize them into
                        manageable study decks. Study and Review: Begin your
                        learning sessions. Use our intuitive interface to review
                        topics at scheduled intervals. Track Your Progress:
                        Monitor your learning progress and see how spaced
                        repetition enhances your retention over time.
                      </p>
                    </div>
                  </div>
                  <h2 className="mt-5">
                    Schedule Review Sessions Strategically
                  </h2>
                  <div className="tm-whyus-blocks">
                    <div className="tm-whyus-block">
                      <p>
                        To harness the full power of spaced repetition, it's
                        essential to schedule your review sessions
                        strategically. Instead of cramming all at once, create a
                        well-structured study plan that incorporates spaced
                        review sessions. This will reinforce your understanding
                        and ensure the information stays with you for the long
                        haul.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-lg-6">
                <div className="tm-whyus-image">
                  <img src={stud} className="img-fluid" alt="us" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-whyus-content">
                  <h2>Join Thousands of Satisfied Users</h2>
                  <div className="tm-whyus-blocks">
                    <div className="tm-whyus-block">
                      <p>
                        Spaced repetition has garnered a loyal following of
                        thousands of satisfied users. They've witnessed
                        firsthand the remarkable transformation it can bring to
                        their learning experiences. Whether it's language
                        learning, exam preparation, or professional development,
                        the benefits of spaced repetition are universal.
                      </p>
                    </div>
                  </div>
                  <h2 className="mt-5">Elevate Your Learning Today</h2>
                  <div className="tm-whyus-blocks">
                    <div className="tm-whyus-block">
                      <p>
                        Are you ready to take your learning to the next level?
                        Spaced repetition is your key to effortless, efficient,
                        and enduring learning. Embrace this proven method and
                        start your journey towards becoming a more knowledgeable
                        and skilled individual. Unlock your full learning
                        potential today.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="tm-blogs-area"
          className="tm-blog-area tm-section tm-padding-section bg-light mt-5"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="text-center">
                  <h2>Mastering Lifelong Learning with Spaced Repetition</h2>
                  <span className="tm-sectiontitle-divider">
                    -----------------------------------------
                  </span>
                  <p>
                    Learning is a lifelong adventure, and with spaced
                    repetition, <br /> you're equipped with the ultimate tool to
                    make your journey as smooth and rewarding as possible. Don't
                    wait; start today and discover the difference it can make in
                    your pursuit of knowledge and personal growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default GetStarte;
