import "../assets/styles/bootstrap.min.css";
import "../assets/styles/style.css";

import BrandLogo from "../assets/images/Rapid learn-logos_transparent1.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header className="sticky-top bg-white shadow-sm">
      <nav className="navbar navbar-expand-lg navbar-light container py-0">
        <Link to="/" className="navbar-brand d-flex align-items-center">
          <img
            src={BrandLogo}
            className="brand-logo"
            width="150"
            alt="Rapid Learn"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ps-4">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            {/* Uncomment if you need About page
            <li className="nav-item">
              <Link to="/about" className="nav-link">
                About
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/get-started" className="nav-link ms-3">
                Get Started
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/contact" className="nav-link ms-3">
                Contact
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/auth/sign-up" className="btn btn-red ms-3 mt-2 mt-lg-0">
                Sign Up
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/auth/login" className="btn btn-red mt-3 ms-3 mt-lg-0">
                Login
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
