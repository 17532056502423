import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import s3 from "./assets/images/girl-2771936_1920.jpg";
import timetable from "./assets/images/timetable.png";
import memorize from "./assets/images/memorize.png";
import reminders from "./assets/images/reminders.png";
import unlock from "./assets/images/cd4df29d4616489ab5ff6dde4bd35bfd.jpeg";
import sec5 from "./assets/images/sec5.webp";
import learn from "./assets/images/learn.jpg";
import tm from "./assets/images/tm.jpg";
// import { Link } from "react-router-dom";
import success from "./assets/images/pexels-george-dolgikh-551816-1326947.jpg";
import brain from "./assets/images/students-1807505_1920.jpg";

function HomeCarousel() {
  return (
    <div className="container-fluid p-0 carousel-container">
      <Carousel
        showArrows={true}
        showIndicators={true}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        autoPlay={false}
        stopOnHover={false}
        swipeable={true}
        dynamicHeight={false}
      >
        <div className="carousel-slide position-relative">
          <img src={success} alt="Slide 1" className="d-block w-100" />
          <div className="carousel-overlay">
            <div className="hero-box text-right">
              <h1 className="hero-title">
                Master Tasks with Spaced Repetition
              </h1>
              <span className="hero-subtitle mt-4">
                Boost productivity and retain knowledge with intelligent task
                scheduling. Achieve more by doing less.
              </span>
              <div className="hero-buttons mt-5">
                <button className="btn btn-red">Login</button>
                <button className="btn btn-outline-red ms-5">
                  Get Started
                </button>
              </div>
            </div>
            <p className="legend fs-4 fs-md-5 fs-lg-6 text-center">
              Effortless Learning: Remember More with Less Effort
            </p>
          </div>
        </div>
        <div className="carousel-slide position-relative">
          <img src={s3} alt="Slide 2" className="d-block w-100" />
          <div className="carousel-overlay">
            <div className="hero-box text-right">
              <h1 className="hero-title">Unlock the Power of Memory</h1>
              <span className="hero-subtitle mt-4">
                Use spaced repetition to unlock the full potential of your
                memory and retain information longer.
              </span>
              <div className="hero-buttons mt-5">
                <button className="btn btn-red">Login</button>
                <button className="btn btn-outline-red ms-5">
                  Get Started
                </button>
              </div>
            </div>
            <p className="legend fs-4 fs-md-5 fs-lg-6 text-center">
              Accelerated Learning: Your Key to Knowledge Retention
            </p>
          </div>
        </div>
        <div className="carousel-slide position-relative">
          <img src={brain} alt="Slide 3" className="d-block w-100" />
          <div className="carousel-overlay">
            <div className="hero-box text-right">
              <h1 className="hero-title">Achieve Learning Mastery</h1>
              <span className="hero-subtitle mt-4">
                Reach new heights of knowledge retention and improve your
                learning efficiency with our smart tools.
              </span>
              <div className="hero-buttons mt-5">
                <button className="btn btn-red">Login</button>
                <button className="btn btn-outline-red ms-5">
                  Get Started
                </button>
              </div>
            </div>
            <p className="legend fs-4 fs-md-5 fs-lg-6 text-center">
              Mastering Memory: Learn, Remember, Repeat
            </p>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

function Home() {
  return (
    <>
      <HomeCarousel />
      <main>
        <div
          id="tm-features-area"
          className="tm-feature-area tm-section bg-light py-5"
        >
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                <img
                  src={timetable}
                  className="feature-icon w-25 mx-auto p-3"
                  alt="Feature Icon 1"
                />
                <h5 className="feature-title fw-bold">
                  Intelligent Scheduling
                </h5>
                <p className="feature-text">
                  Automatically calculates optimal review intervals for each.
                  Maximizes memory retention with minimal effort.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                <img
                  src={memorize}
                  className="feature-icon w-25 mx-auto p-3"
                  alt="Feature Icon 2"
                />
                <h5 className="feature-title fw-bold">
                  Active Recall Integration
                </h5>
                <p className="feature-text">
                  Promotes active engagement by requiring recall of answers.
                  Reinforces learning through self-testing.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                <img
                  src={reminders}
                  className="feature-icon w-25 mx-auto p-3"
                  alt="Feature Icon 3"
                />
                <h5 className="feature-title fw-bold">
                  Reminders and Notifications
                </h5>
                <p className="feature-text">
                  Get notifications to revisit previously studied content.
                  Maintain your progress towards achieving your learning
                  objectives.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          id="tm-about-area"
          className="tm-about-area tm-padding-section py-5 section-3"
        >
          <div className="container ">
            {/* About Section */}
            <div className="row align-items-center ">
              <div className="col-lg-6">
                <div className="">
                  <h2 className="mb-4 sec-feature-title">
                    Unlock Your True Learning Potential
                  </h2>
                  <ul className="list">
                    <li>
                      <i className="ion-android-checkmark-circle text-primary me-2" />
                      Spaced repetition is a science-backed method that
                      transforms learning.
                    </li>
                    <li>
                      <i className="ion-android-checkmark-circle text-primary me-2" />
                      It enhances memory retention and accelerates learning.
                    </li>
                    <li>
                      <i className="ion-android-checkmark-circle text-primary me-2" />
                      Schedule review sessions strategically for stronger
                      memory.
                    </li>
                    <li>
                      <i className="ion-android-checkmark-circle text-primary me-2" />
                      Join thousands of satisfied users and elevate your
                      learning.
                    </li>
                    <li>
                      <i className="ion-android-checkmark-circle text-primary me-2" />
                      Start your journey to effortless, efficient, and enduring
                      learning today.
                    </li>
                  </ul>
                  {/* <div className="tm-about-buttons mt-4">
            <Link to={"/maximize-your-learning-power"} className="btn btn-primary rounded-pill px-4 py-2">
              Read More
            </Link>
          </div> */}
                </div>
              </div>
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="tm-videobox text-center">
                  <img src={unlock} className="img-fluid" alt="Videobox" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="tm-about-area"
          className="tm-about-area tm-padding-section py-5 section-4"
        >
          <div className="container ">
            {/* About Section */}
            <div className="row align-items-center ">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="tm-videobox text-center">
                  <img src={sec5} className="img-fluid" alt="Videobox" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ms-5">
                  <h2 className="mb-4 sec-feature-title">
                    Three Compelling Reasons to Embrace Spaced Repetition for
                    Learning
                  </h2>
                  <div className="tm-whyus-blocks">
                    {/* Reason 1 */}
                    <div className="tm-whyus-block d-flex align-items-center mb-4">
                      <div className="rounded-circle bg-danger text-center text-white d-flex align-items-center justify-content-center me-3 shadow-lg">
                        <span className="fs-4 px-2 py-1">1</span>
                      </div>
                      <div>
                        <h5>Remember Longer</h5>
                        <p>Master subjects with lasting retention.</p>
                      </div>
                    </div>
                    {/* Reason 2 */}
                    <div className="tm-whyus-block d-flex align-items-center mb-4">
                      <div className="rounded-circle bg-danger text-center text-white d-flex align-items-center justify-content-center me-3 shadow-lg">
                        <span className="fs-4 px-2 py-1">2</span>
                      </div>
                      <div>
                        <h5>Efficient Study Sessions</h5>
                        <p>Save time by focusing on what you need to review.</p>
                      </div>
                    </div>
                    {/* Reason 3 */}
                    <div className="tm-whyus-block d-flex align-items-center mb-4">
                      <div className="rounded-circle bg-danger text-center text-white d-flex align-items-center justify-content-center me-3 shadow-lg">
                        <span className="fs-4 px-2 py-1">3</span>
                      </div>
                      <div>
                        <h5>Boost Learning Speed</h5>
                        <p>
                          Achieve faster progress with scientifically backed
                          methods.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="tm-about-buttons mt-4">
            <Link to={"/maximize-your-learning-power"} className="btn btn-primary rounded-pill px-4 py-2">
              Read More
            </Link>
          </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Why Us Section */}
        <div className="row align-items-center section-5 py-5 px-5">
          {/* Left Side - Image with Overlay */}
          <div className="col-lg-6 position-relative">
            <div className="tm-whyus-image text-center">
              <img
                src={learn}
                className="img-fluid rounded"
                alt="Why Us"
              />
              {/* Decorative overlay element */}
              <div className="position-absolute top-0 start-0 w-100 h-100 opacity-10"></div>
            </div>
          </div>

          {/* Right Side - Content with Icons and Dividers */}
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="tm-whyus-content">
              <h2 className="mb-4 tw">We Care About Your Success</h2>
              <p className="lead tw">
                Every learner and professional has a unique journey. We're here
                to guide you with personalized tools that fit your learning
                style.
              </p>

              {/* Mission */}
              <div className="d-flex align-items-start mb-4">
                <i className="fas fa-bullseye text-success me-3 fs-3"></i>
                <div>
                  <h4 className="fw-bold tw">OUR MISSION</h4>
                  <p className="tw">
                    Empowering individuals to maximize their learning potential
                    through intelligent, spaced repetition strategies that boost
                    retention and productivity.
                  </p>
                </div>
              </div>

              {/* Vision */}
              <div className="d-flex align-items-start mb-4">
                <i className="fas fa-eye text-warning me-3 fs-3"></i>
                <div>
                  <h4 className="fw-bold tw">OUR VISION</h4>
                  <p className="tw">
                    To become the leading platform for smart task management,
                    helping learners and professionals alike to achieve their
                    goals with greater efficiency and effectiveness.
                  </p>
                </div>
              </div>

              {/* About Us */}
              <div className="d-flex align-items-start mb-4">
                <i className="fas fa-info-circle text-info me-3 fs-3"></i>
                <div>
                  <h4 className="fw-bold tw">ABOUT US</h4>
                  <p className="tw">
                    We are passionate about helping you unlock your full
                    potential. Our platform is built on the foundation of proven
                    learning methodologies, designed to make task management
                    simple and effective for everyone.
                  </p>
                </div>
              </div>

              {/* Certifications */}
              <div className="d-flex align-items-start mb-4">
                <i className="fas fa-certificate text-warning me-3 fs-3"></i>
                <div>
                  <h4 className="fw-bold tw">CERTIFICATIONS</h4>
                  <p className="tw">
                    Our platform is trusted and backed by professionals
                    worldwide. We adhere to the highest standards of learning
                    and productivity technologies, ensuring that our tools make
                    a lasting impact on your success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="tm-blog-area"
          className="tm-blog-area tm-section bg-light py-5 d-none"
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-4">
                <h2 className="display-4">Latest From Our Blog</h2>
                <p>
                  Stay updated with the latest tips and strategies for effective
                  learning.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="card border-0 shadow-lg">
                  <img src={tm} className="card-img-top" alt="Blog Post 1" />
                  <div className="card-body">
                    <h5 className="card-title">
                      The Science Behind Spaced Repetition
                    </h5>
                    <p className="card-text">
                      Explore how spaced repetition enhances memory retention
                      through scientific principles.
                    </p>
                    <a href="#" className="btn btn-primary rounded-pill">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card border-0 shadow-lg">
                  <img
                    src={timetable}
                    className="card-img-top"
                    alt="Blog Post 2"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      How to Create Effective Study Schedules
                    </h5>
                    <p className="card-text">
                      Learn how to design study schedules that optimize your
                      learning efficiency.
                    </p>
                    <a href="#" className="btn btn-primary rounded-pill">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card border-0 shadow-lg">
                  <img
                    src={success}
                    className="card-img-top"
                    alt="Blog Post 3"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      Maximizing Learning with Minimal Effort
                    </h5>
                    <p className="card-text">
                      Discover strategies to make the most of your learning with
                      the least amount of effort.
                    </p>
                    <a href="#" className="btn btn-primary rounded-pill">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Home;
