import { Link } from 'react-router-dom';
import BrandLogo from '../assets/images/Rapid learn-logos_transparent1.png'


function Footer() {
    return(
        <footer className="tm-footer mt-5">
            <div className="tm-footer-topside tm-padding-section cst-footer">
                <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-12 mt-3">
                    <div className="single-widget widget-info">
                        <Link to={'/'} className="widget-info-logo text-decoration-none">
                            <img src={BrandLogo} alt="Rapid Learn" width="250px" style={{ backgroundColor: '#f8f9fa' }} />
                        </Link>
                        <p className="mt-3">Learn better with us using spaced repetition.</p>
                        <ul className="d-flex d-none">
                            <li><Link to={'/'} ><i className="ion-social-facebook" /></Link></li>
                            <li><Link to={'/'} ><i className="ion-social-twitter" /></Link></li>
                            <li><Link to={'/'} ><i className="ion-social-instagram-outline" /></Link></li>
                            <li><Link to={'/'} ><i className="ion-social-pinterest-outline" /></Link></li>                           
                        </ul>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 mt-3">
                    <div className="single-widget widget-quicklinks">
                        <h4 className="widget-title fs-4">Pages</h4>
                        <ul className='mt-3'>
                            <li className='mb-2'><Link to={'/'} className='text-decoration-none cst-link'>Home</Link></li>
                            {/* <li className='mb-2'><Link to={'/blogs'} className='text-decoration-none cst-link'>Blogs</Link></li> */}
                            {/* <li className='mb-2'><Link to={'/about'} className='text-decoration-none cst-link'>About</Link></li> */}
                            <li className='mb-2'><Link to={'/contact'} className='text-decoration-none cst-link'>Contact</Link></li>
                        </ul>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 mt-3">
                        {/* <div className="single-widget widget-recentpost-2">
                            <h4 className="widget-title fs-4">Recent Posts</h4>
                            <ul>
                            <li className='mb-2'>
                                <h6><Link to={'/blog/1'} className='text-decoration-none text-white-50 cst-link'>Blog 1</Link></h6>
                                <span><i className="ion-android-calendar" /> August 12, 2023</span>
                            </li>
                            <li>
                                <h6><Link to={'/blog/2'} className='text-decoration-none text-white-50 cst-link'>Blog 2</Link></h6>
                                <span><i className="ion-android-calendar" /> August 12, 2023</span>
                            </li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 mt-3">
                    <div className="single-widget widget-hours">
                        <h4 className="widget-title fs-4">Contact Us</h4>
                        <p className='mt-3'>Feel at ease reaching out to us.</p>
                        <ul>
                            <li><span>Mail ID</span>: rapidlearn@gmail.com</li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="tm-footer-bottomside cst-copyright">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12">
                    <p className="tm-footer-copyright text-center mt-2 fw-bold">
                        Copyright © Rapit Learn | 2023
                    </p>
                    </div>
                </div>
                </div>
            </div>
        </footer>

    )    
}

export default Footer;