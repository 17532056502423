import { get_logged_data } from "../../../service/Configuration";

const home_get = (endpoint, data={}) => {
    return fetch(endpoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

const home_post = (endpoint, data) => {    
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}


const home_basic_auth = (endpoint, data) => {
    const {email, password} = data;
    let encodedString = btoa(`${email}:${password}`);
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${encodedString}`
        }        
    });
}


const home_auth_post = (endpoint, data) => {
    const token = get_logged_data();
    return fetch(`/api/${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
    });
}

export { home_get, home_post, home_basic_auth, home_auth_post}